import React, { useState, useEffect } from 'react';
import Feild from "../../assets/feild.png";
import { useNavigate } from "react-router-dom";
import Gift from "../../components/gift";
import { useParams } from "react-router-dom";

export default function Index({item}) {
        
  const [sendgift, setSendgift] = useState(false);      
  const { nftslug } = useParams();
  const { code } = useParams();
  
  let navigate = useNavigate();
  
  const handleNavigate = (e) => {
    e.preventDefault();

    if(typeof code!=='undefined'){
        navigate("/retreat/"+item.redemption.post_name+'/'+nftslug+'/'+code);
    }else if(typeof nftslug!=='undefined'){
        navigate("/retreat/"+item.redemption.post_name+'/'+nftslug);
    }else{
        navigate("/retreat/"+item.redemption.post_name);
    }
    
  };
  const sendAsGift = () =>{
      setSendgift(true);
  }
  const handleSendgift = () =>{
       setSendgift(false);
  }
  
  return (
    <>
      <main
      
        className="nft_market_"
        style={{ paddingBottom: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
                REDEEM RETREAT
              </h1>
               <button className="mt-3 mb-3 giftbtn" onClick={sendAsGift}>Send as Gift</button>
              <h2 className="text-center d-block">Choose Location</h2>
            </div>
          </div>

          <div
            style={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <img src={Feild} style={{ width: "100%" }} alt=""   onClick={(e) => handleNavigate(e)}/>
          </div>
        </div>
      </main>
      {sendgift && <Gift item={{
                name:item.redemption.post_title,
                slug:nftslug,
                service:item.redemption.post_name,
                type:'service'
            }} onClose={handleSendgift} />}
    </>
  );
}
