import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import AccessTime from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Select, { components } from 'react-select'
import { GifBoxOutlined, Sms, Wallet, EmailOutlined } from '@mui/icons-material';
import axiosClient from "../../helper";
import { useCookies } from 'react-cookie';
import CircularProgress from "@mui/material/CircularProgress";
import Gift from "../../components/gift";
import { useParams } from "react-router-dom";

function populate(starttime, endtime) {
  var slots = [];
  var hours, minutes, ampm;
  for(var i = starttime; i <= endtime; i += 30){
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10){
          minutes = '0' + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours = hours % 12;
      if (hours === 0){
          hours = 12;
      }
      if (hours < 10){
        hours = '0'+hours;
    }      
      slots.push({
        value:i,
        label:hours + ':' + minutes + ' ' + ampm
      });
  }

  for(i=0;i<slots.length;i++){
    if(typeof slots[i+1] !=='undefined'){
      slots[i].label = slots[i].label+' - '+ slots[i+1].label;
      slots[i].value = slots[i].label;
    }
    
  }
  slots.pop();

  return slots;
}




export default function Index({item}) {
  const [open, setOpen] = React.useState(false);
  const [gift, setGift] = React.useState(false);
  const [giftsent, setGiftsent] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [timeslots, setTimesloat] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [clinicid, setClinicid] = React.useState(1);
  const [slotid, setSlotid] = React.useState(1);
  const [options, setOptions] = React.useState([]);
  const [loadingoptions, setLoadingOptions] = React.useState(false);
  const [sendgift, setSendgift] = useState(false);
  const { nftslug } = useParams();
  const { code } = useParams();


  const loadSlots = (sdate) => {
      var soptions = [];
      setOptions([]);
      setTime([]);
      setLoadingOptions(true);
      var from = new Date(sdate.$y+'-'+(sdate.$M+1)+'-'+(sdate.$D)+ ' 00:00:00');
      var to = new Date(sdate.$y+'-'+(sdate.$M+1)+'-'+(sdate.$D)+ ' 23:59:59');
      axiosClient()
      .get('https://wake.advancedcare.com/api/public/v1/booking?action=time_slot&service_id='+item.service_id+'&from='+Number(from)+'&to='+Number(to))
      .then((res) => {
          console.log(res.data);
          console.log(res.data.result);
          if(res.data.result){
              var fstart = Number(from);
              var fend = Number(to);
              for(var ii=0; ii<res.data.result.length; ii++){
                  
                  console.log(new Date(res.data.result[ii].start));
                  if(res.data.result[ii].start >= fstart && res.data.result[ii].end <= fend ){
              soptions.push({
                  label:new Date(res.data.result[ii].start).toLocaleTimeString()+' - '+(new Date(res.data.result[ii].end).toLocaleTimeString()),
                  value:res.data.result[ii].id
              });
                }
            }
            setOptions(soptions);
            setLoadingOptions(false);
          }
        
      })
      .catch(() => {
          setLoadingOptions(false);
      });
  }
  const CaretDownIcon = () => {
    return <AccessTime style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "35px" }} />;
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };  
  const Placeholder = props => {
    return (
      <components.Placeholder {...props}>
        Select Time
      </components.Placeholder>
    );
  };  

  const handleOpen = () => {
    
   axiosClient()
    .post(`/wakenft/v1/redeem`,{
        email:email,
        walletaddress:localStorage.getItem('wallet'),
        slug:item.redemption.post_name,
        date:date,
        time:time.value,
        slotid:slotid,
        serviceid:item.service_id,
        clinicid:item.clinicid,
        timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
        code:code
    })
    .then((res) => {

        if(res.data.status===200){
            setOpen(true);
        }else{
            alert(res.data.message);
        }
        
        
    }).catch(() => {
      console.log('sendEmail, error');
    });
  
    
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenGift = () => {
    setOpen(false);
    setGift(true);
  };
  
  const handleOpenGiftsent = () => {
      axiosClient()
    .post(`/wakenft/v1/sendgift`,{
        to:email,
        subject:'Got a gift',
        content:'You got a gift of '+item.redemption.post_title
    })
    .then((res) => {
            navigate("/gift-thanks/"+item.redemption.post_title);    
            setOpen(false);
            setGift(false);
            setGiftsent(true);
    })
    .catch(() => {
      console.log('sendEmail, error');
    });
  

  };  
  const handleCloseGift = () => {
    setGift(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
  };  
  let navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/dashboard");
  };
  const handleNavigateAccount = (e) => {
    e.preventDefault();
    console.log(item)
   // navigate("/gift-thanks/"+item.title.rendered, { replace: true });
  };
      const sendAsGift = () =>{
      setSendgift(true);
  }
  const handleSendgift = () =>{
       setSendgift(false);
  }
  
  return (
    <>

      <main className="nft_market_" style={{ paddingBottom: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
              {item?.redemption.post_title}
              </h1>
              <button className="mt-3 mb-3 giftbtn" onClick={sendAsGift}>Send as Gift</button>
              <h2 className="text-center d-block">Select a date and timeslot</h2>
              <Grid container>
                    <Grid
            
                      item
                      xs={12}
                      className="redemption-item"
                    >
                  
                      <Grid style={{ width: "100%", height: "120px" }}>
                        <img
                          className="redemption-image"
                          src={`${item?.image}`}
                          alt=""
                        />
                      </Grid>
                      <Grid className="redemption-text">
            
                        <div className="redemption_store_text">
                         
                            {item?.heading.split('|').map(function(item) {
    return (
       <span>{item}</span>
    )
 })}
                      
                          
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
  
              <div style={{
                backgroundColor:'#fff',
                paddingBottom:'15px'
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={date}
        onChange={(newValue) => {
          setDate(newValue);
          loadSlots(newValue);
        }}
        minDate={new Date()}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

</div>
      {loadingoptions?(
               <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
                ):(
                <>
<div className='timepickerc'>

      <Select options={options}   onChange={(newValue) => {
  
          setTime(newValue);
          setSlotid(newValue.value)
        }}
        components={{ DropdownIndicator, Placeholder }}
        className="slotpicker" 
        classNamePrefix='tsp' 
        isClearable={true}
        value={time}
        />
      
  </div>
  {code && (<div className="col-12 main-info p-0">
  <input type="text" name="email" placeholder="Enter your email" value={email}
                            onChange={(newValue) => {
                              setEmail(newValue.value);
                            }} 
        style={{
                            width: "100%",
                            height: "60px",
                            borderRadius: "10px",
                            padding: "10px",
                            marginBottom: "10px",
                            border: "1px solid gray",
                          }}                    
        />
                </div>)}
            <div className="col-12 main-info p-0">
              <a
                style={{ cursor: "pointer" }}
                onClick={handleOpen}
                className="redeemBtn mt-3 showconfirm"
              >
                Confirm
              </a>
            </div>
              </>)}
              </div>
            {/*
            <div className="col-12 main-info p-0">
              <button
                type="button"
                className="modalBtn"
                style={{ justifyContent: "space-between", display: "flex" }}
                onClick={handleOpen}
              >
                Choose Strain
                <ArrowForwardIosIcon style={{ color: "#89858B" }} />
              </button>
            </div>
            <div className="col-12 p-0">
              <p
                className="text-center"
                style={{ fontSize: "20px", fontWeight: "lighter" }}
              >
                Not Sure?
              </p>
            </div>
            <div className="col-12 p-0 mt-3 mb-3">
              <a
                onClick={(e) => handleNavigate(e)}
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                }}
                className="modalBtn"
              >
                Choose For Me
                <ArrowForwardIosIcon style={{ color: "#89858B" }} />
              </a>
            </div> */}
          </div>
        </div>
      </main>

      <div className={open ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            {/* <button onClick={handleClose} className="btn-close" /> */}
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item?.redemption.post_title} is booked. You will receive an email with further details.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigate} className="redeemBtn mt-3">
              go to Dashboard
            </a>
            
          </div>
        </div>
        <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="email" placeholder='Enter Email' onChange={(e)=>{ setEmail(e.target.value)}}/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="tel" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item?.redemption.post_title} gift is sent.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
        </div>
        <div className={open ? "overloay1" : "overloay2"}></div>
        <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
                          {sendgift && <Gift item={{
                name:item.redemption.post_title,
                slug:nftslug,
                service:item.redemption.post_name,
                type:'service'
            }} onClose={handleSendgift} />}
    </>
  );
}
