import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import Redemption1 from "../../components/redemption1";
import Redemption2 from "../../components/redemption2";
import Redemption3 from "../../components/redemption3";
import { useParams } from "react-router-dom";
import axiosClient from "../../helper";
import Myaccount from "../../components/myaccount";
import Gift from "../../components/gift";

export default function Index() {
  const { component } = useParams();
  const { nftslug } = useParams();
  const { code } = useParams();
  const [product, setProduct] = useState(true);
  const [productloading, setLoading] = useState(true);
  const [sendgift, setSendgift] = useState(false);
  
  
  
  const getProduct = (component) => {
      if(productloading==true){
    axiosClient()
    .get(`/wakenft/v1/userredepmtions?slug=`+component+`&walletaddress=`+localStorage.getItem('wallet')+'&nft='+nftslug+'&code='+code)
    .then((res) => {
        
        console.log(res.data.data[0]);
      setProduct(res.data.data[0]);
        setLoading(false);
    })
    .catch(() => {
      setLoading(false);
      setProduct(false);
    });
      }
  }
  

  return (
    <div className="nft_market">
        {!code && (
      <div className="d-flex justify-content-between">
        <Link to="/dashboard">
    
          <ArrowBackIcon style={{ color: "gray", fontSize: "35px" }} />
 
        </Link>
        <Myaccount  />
       
      </div>
        )}
      {getProduct(component)}
      {productloading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ):(<>
           
            {product && product.type === "1" ? <Redemption1 item={product}  /> : null}
            {product && product.type === "2" ? <Redemption2 item={product}  /> : null}
            {product && product.type === "3" ? <Redemption3 item={product}  /> : null}
            {!product && (
                    <h1>Redemption Not Found</h1>    
                )}
            </>)}
    </div>
  );
}
