import React, { useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Video from "../../assets/video.mp4";
export default function Index({ wallett, setWallett, walletAddress, loading }) {
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);

  const getWalletMessage = () => {
    if (loading) {
      return "Loading Wallet...";
    }
    setWallett(walletAddress);

    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };

  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  return (
    <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/dashboard">
          <a>
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        </Link>
        <div className="dropdown">
          <Button
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              borderRadius: "40px",
              padding: "10px",
              backgroundColor: "#404040",
              color: "#fff",
              fontSize: "13px",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          >
            {wallett ? <>Welcome{getWalletMessage()}</> : null}
          </Button>
        </div>
      </div>

      <main className="nft_market_">
        <div className="container pt-0">
          <div className="row">
            <div className="col-12 p-0">
              <h1 className="text-center d-block">View Your Grow</h1>
            </div>
          </div>
          <div
            className="col-12 main-info p-0 pt-3  text-center"
            style={{ marginBottom: 0, marginTop: 20 }}
          >
            <a href="" style={{ padding: "0 15px", color: "#000" }}>
              <i className="fab fa-facebook-f" style={{ color: "#000" }} />
            </a>
            <a href="" style={{ padding: "0 15px", color: "#000" }}>
              <i className="fab fa-twitter" style={{ color: "#000" }} />
            </a>
            <a href="" style={{ padding: "0 15px" }}>
              <i className="fab fa-instagram" style={{ color: "#000" }} />
            </a>
            <a href="" style={{ padding: "0 15px" }}>
              <i className="fab fa-linkedin" style={{ color: "#000" }} />
            </a>
            <a href="" style={{ padding: "0 15px" }}>
              <i className="fab fa-discord" style={{ color: "#000" }} />
            </a>
          </div>
          <div className="row align-items-center pt-3">
            <video width={320} height={240} controls="" autoPlay={true}>
              <source src={Video} type="video/mp4" />
            </video>
          </div>
          <div className="col-12 p-0 pt-5 text-center">
            <h1 className=" d-block">Grow Schedule (Approx.)</h1>
            <p style={{ paddingBottom: "1em" }}>
              <b>Incubation</b>
              <br />4 October 2022
            </p>
            <p
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "1em 0",
              }}
            >
              <b>Pinning</b>
              <br />1 Aug 2022
            </p>
            <p style={{ paddingTop: "1em" }}>
              <b>Picking</b>
              <br />8 Aug 2022
            </p>
          </div>
          <div className="col-12 main-info p-0 pt-3"></div>
        </div>
      </main>
      <div className={open ? "confirmpopup" : "confirmpopup2"}>
        <div style={{ justifyContent: "right", display: "flex" }}>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <div className="confirmpopup-content">
          <h1 className="text-center d-block">THANK YOU</h1>
          <p>
            You will receive updates to your email when your Mushroom grow pod
            is ready to start.
          </p>
        </div>
        <div className="confirmpopup-bottom">
          <a href="/penisenvy_Video" className="redeemBtn mt-3">
            View Your Grow
          </a>
        </div>
      </div>
      <div className={back ? "overloay1" : "overloay2"}></div>
    </div>
  );
}
