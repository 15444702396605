import React, { useState, useEffect } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Metamask from "../../assets/metamask.png";
import axiosClient from "../../lib/helper";
import {connectWalletPressed} from "../../lib/wallet";

export default function Landing() {
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);
  const [wallet, setWallet] = useState(false); 
  const [loading, setLoading] = useState(false);  
  
  let navigate = useNavigate();
   useEffect(() => {
        var status = localStorage.getItem('walletstatus');
        console.log('status',status);
        if(status==='CONNECTED'){            
            setWallet(true);
        }
    }, []);  
  const handleClick = () => {
  
    if (wallet) {
      setOpen(false);
      navigate("/dashboard", { replace: true });
    } else {
      setOpen(true);
      setBack(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/nft-market", { replace: true });
  };

  const handleNavigate2 = (e) => {
    e.preventDefault();
    if (wallet) {
      navigate("/dashboard");
    } else {
        setLoading(true);
        connectWalletPressed(true,function(){
            
            setLoading(false);
            var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWallet(true);
                navigate("/dashboard");
            }else{
                navigate("/dashboard");
            }
        })
    }
  };
  
  const connectWallet = (state) => {
            
        setLoading(true);
        connectWalletPressed(state,function(){
            
            setLoading(false);
            var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWallet(true);
            }
        })
       
    }
  
  return (
    <>
      <div className="nft_market">
        <main className="home">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1
                  className="text-center d-block m-0"
                  style={{ fontWeight: "lighter" }}
                >
                  <span className="d-block">WELCOME TO</span>WAKE WellPass NFT
                </h1>
                <p
                  className="text-center"
                  style={{ fontWeight: "lighter", fontSize: "18px" }}
                >
                  A place where you can buy Wake WellPass NFTs and redeem exclusive rewards while being part of a global community to further research by joining the Wake WellPass community.
                </p>
                <a
                  onClick={(e) => handleNavigate(e)}
                  className="btn btn-primary m-auto "
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <span style={{ textDecoration: "none" }}>
                    WAKE NFT Marketplace
                  </span>
                  <span>
                    <StorefrontIcon />
                  </span>
                </a>

                <p
                  className="text-center pb-4"
                  style={{ fontWeight: "lighter", fontSize: "20px" }}
                >
                  Already own a WAKE NFT?
                </p>
                <a
                  onClick={handleClick}
                  className="btn btn-primary m-auto store showconfirm"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Go to Dashboard
                  <span>
                    <CardGiftcardIcon />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </main>
        <div className={open ? "confirmpopupconnect" : "confirmpopupconnect2"}>
          <button className="btn-close" onClick={handleClose}></button>
          <div className="confirmpopup-content2">
            <img
              width="50px"
              height="40px"
              style={{ marginTop: "10px" }}
              src={Metamask}
              alt=""
            />
            <p className="metamask2">Connect Metamask Wallet</p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={(e) => handleNavigate2(e)} className="redeemBtn mt-3">
              Connect Wallet
            </a>
          </div>
        </div>
        <div className={back ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
