import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Wake from "../../assets/wake.jpg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { GifBoxOutlined, Sms, Wallet, EmailOutlined } from '@mui/icons-material';
import axiosClient from "../../helper";
import Gift from "../../components/gift";
import { useParams } from "react-router-dom";


export default function Index({item}) {
        

  const [open, setOpen] = useState(false);
  const [gift, setGift] = React.useState(false);
  const [giftsent, setGiftsent] = React.useState(false);  
  const [back, setBack] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [email, setEmail] = React.useState(null);
  const [first_name, setFirstname] = React.useState(null);
  const [last_name, setLastname] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [zipcode, setZipcode] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  
  const [product, setProduct] = useState(true);
  const { nftslug } = useParams();
  const { code } = useParams();
  

  
  const [sendgift, setSendgift] = useState(false);
  
  let navigate = useNavigate();



  const handleClick = () => {
    setOpen(true);
    setBack(true);
  };
  const handleClick2 = () => {

    
   axiosClient()
    .post(`/wakenft/v1/redeem`,{
        email:localStorage.getItem('email'),
        walletaddress:localStorage.getItem('wallet'),
        slug:item.redemption.post_name,
        date:new Date(),
        time:new Date(),
        code:code,
        type:item.type,
        first_name:first_name,
        last_name:last_name,
        phone:phone,
        email:email,
        address:address,
        country:country,
        city:city,
        state:state,
        zipcode:zipcode
    })
    .then((res) => {
        
        if(res.status===200){
            setOpen3(true);
            setOpen(false);
        }else{
            alert(res.message);
        }

    }).catch(() => {
      console.log('sendEmail, error');
    });
  
  };
  const handleClose = () => {
    setOpen(false);
    setBack(false);
    setOpen3(false);
  };
  const handleOpenGift = () => {
    setOpen3(false);
    setGift(true);
  };
  const handleOpenGiftsent = () => {

      axiosClient()
    .post(`/wakenft/v1/sendgift`,{
        to:email,
        subject:'Got a gift',
        content:'You got a gift of '+item.redemption.post_name
    })
    .then((res) => {
            navigate("/gift-thanks/"+item.redemption.post_name);    
            setOpen(false);
            setGift(false);
            setGiftsent(true);
    })
    .catch(() => {
      console.log('sendEmail, error');
    });
  
  };  
  const handleCloseGift = () => {
    setGift(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
  };  
  const handleNavigateAccount = (e) => {
    e.preventDefault();

    navigate("/dashboard", { replace: true });
  };
  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/dashboard");
  };
    const sendAsGift = () =>{
      setSendgift(true);
  }
  const handleSendgift = () =>{
       setSendgift(false);
  }
  return (
    <>
      <main className="nft_market_" style={{ paddingBottom: "100px" }}>
        <div className="container ">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
                REDEEM WAKE KIT
              </h1>
            </div>
                  <button className="mt-3 mb-3 giftbtn" onClick={sendAsGift}>Send NFT as Gift</button>
            <div className="col-12 main-info p-0">
              <img src={Wake} alt="" className="img-fluid" />
            </div>
            <div className="col-12 main-info p-0">
              <select
                style={{
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  width: "100%",
                }}
                className="form-select "
              >
                <option selected>Choose Size</option>
                <option value="1">Small</option>
                <option value="2">Medium</option>
                <option value="3">Large</option>
              </select>
            </div>

            <div className="col-12 main-info p-0">
              <button
                onClick={handleClick}
                className="redeemBtn mt-4 showselectorder"
              >
                Redeem WAKE Kit
              </button>
            </div>

            <div className={open ? "confirmpopup" : "confirmpopup2"}>
              <div className="orderbox3 ">
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "10px",
                    textAlign: "right",
                  }}
                >
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                <div className="innerscroll">
                  <div className="confirmpopup-content">
                    <div className="orderbox-content ob-bottom">
                      <p>
                        Please enter your shipping address to recieve the WAKE
                        branded kit shown below.
                      </p>
                      {code && (
                        <>
                <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="First Name"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={first_name}
                            onChange={(newValue) => {
                              setFirstname(newValue.value);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Last Name"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={last_name}
                            onChange={(newValue) => {
                              setLastname(newValue.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Email"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={email}
                            onChange={(newValue) => {
                              setEmail(newValue.value);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Phone"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={phone}
                            onChange={(newValue) => {
                              setPhone(newValue.value);
                            }}
                          />
                        </div>
                      </div>
                        </>
                      )}
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Shipping Address"
                          style={{
                            width: "100%",
                            height: "60px",
                            borderRadius: "10px",
                            padding: "10px",
                            marginBottom: "10px",
                            border: "1px solid gray",
                          }}
                          value={address}
                            onChange={(newValue) => {
                              setAddress(newValue.value);
                            }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Country"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={country}
                            onChange={(newValue) => {
                              setCountry(newValue.value);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="State"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={state}
                            onChange={(newValue) => {
                              setState(newValue.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="City"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={city}
                            onChange={(newValue) => {
                              setCity(newValue.value);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Zipcode"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                            value={zipcode}
                            onChange={(newValue) => {
                              setZipcode(newValue.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="confirmpopup-content ob-bottom">
                    <div className="orderbox-content">
                      <b>Kit contents:</b>
                      <ul>
                        <li>Wake branded T-Shirt</li>
                        <li>Hooded Sweater</li>
                        <li>Water Bottle</li>
                        <li>Iris Headphones</li>
                        <li>12 Bottles of Wake functional mushrooms</li>
                        <li>Notebook & Pen</li>
                        <li>Eyeshade</li>
                      </ul>
                    </div>
                    <div className="confirmpopup-bottom">
                      <a
                        onClick={handleClick2}
                        className="redeemBtn mt-3 showconfirm"
                      >
                        Confirm
                      </a>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={open3 ? "orderbox" : "orderbox2"}>
              <div
                style={{
                  paddingTop: "5px",

                  textAlign: "right",
                }}
              >
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>

              <div>
                <button className="btn-close"></button>
                <div className="confirmpopup-content">
                  <h1 className="text-center d-block">THANK YOU</h1>
                  <p>
                    You will recieve a shipping notification once your kit is
                    shipped, and delivery is typically 1-2 weeks depending on
                    where in the world Carmen San Diego is.
                  </p>
                </div>
               
                <div>
                 {!code && (
                  <a onClick={(e) => handleNavigate(e)} className="redeemBtn mt-3">
                    Go to Dashboard
                  </a>
                    )}
                 
                </div>
              
              </div>
            </div>
            <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="email" placeholder='Enter Email' onChange={(e)=>{ setEmail(e.target.value)}}/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="tel" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item.redemption.post_name} gift is sent.
            </p>
          </div>
           {!code && (
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
          )}
        </div>
        <div className={open ? "overloay1" : "overloay2"}></div>
        <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
          </div>
        </div>
      </main>
                  {sendgift && <Gift item={{
                name:item.redemption.post_title,
                slug:nftslug,
                service:item.redemption.post_name,
                type:'service'
            }} onClose={handleSendgift} />}
    </>
  );
}
