var Web3 = require('web3');
export const getCurrentWalletConnected = async (_window) => {
  if (_window.ethereum) {
    try {
      const addressArray = await _window.ethereum.request({
        method: "eth_accounts",
      });

      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
        };
      } else {
        return { address: "", status: "NOT_CONNECTED" };
      }
    } catch (error) {
      return {
        address: "",
        status: "",
        error,
      };
    }
  } else {
    return {
      address: "",
      status: "METAMASK_NOT_INSTALLED",
    };
  }
};

/**
 *
 * @param _window
 * @return {Promise<{address: string, status: string}|{address: string, status}|{address, status: string}>}
 */
export const connectWallet = async (_window) => {
  if (_window.ethereum) {
    try {
      const addressArray = await _window.ethereum.request({method:'eth_requestAccounts'});
      const obj = {
        status: 'CONNECTED',
        address: addressArray[0],
      };

      return obj; 
    } catch (error) {
      return {
        address: "",
        status: "",
        error: error,
      };
    }
  } else {
    return {
      address: "",
      status: 'NOT_INSTALLED',
    };
  }
};

const STATUS = {
  NOT_INSTALLED: "METAMASK_NOT_INSTALLED",
  CONNECTED: "CONNECTED",
};

export const connectWalletPressed = async (hello, callback) => {
    const walletResponse = await connectWallet(window);
    if (walletResponse.error) {
   //   handleWalletError(walletResponse.error);
    }else if(walletResponse.status==='METAMASK_NOT_INSTALLED'){
     // setShowError({ show: true, message:'METAMAST Not Installed' });
    }
    addWalletListener();
    console.log(walletResponse.status);
    localStorage.setItem('walletstatus',walletResponse.status);
    localStorage.setItem("wallet",walletResponse.address);
    localStorage.setItem("token", walletResponse.address);
    callback()
  };
 export  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          localStorage.setItem("wallet",accounts[0]);
        } else {
          localStorage.setItem("wallet","");
          localStorage.setItem('walletstatus',"NOT_CONNECTED");
        }
      });
      window.ethereum.on("chainChanged", (chainId) => {
        //handleChainChanged(chainId);
        window.location.reload(false);
      });
    } else {
      localStorage.setItem('walletstatus',"METAMASK_NOT_INSTALLED");
    }
  };
  
