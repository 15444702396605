import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import Redemption1 from "../../components/redemption1";
import Redemption2 from "../../components/redemption2";
import Redemption3 from "../../components/redemption3";
import { useParams } from "react-router-dom";
import axiosClient from "../../helper";
import Myaccount from "../../components/myaccount";
import Gift from "../../components/gift";
import {connectWalletPressed} from "../../lib/wallet";
import { useNavigate } from "react-router-dom";
import { useCartDapp } from "../../Context";


export default function Index() {
  const { component } = useParams();
  const { connect } = useCartDapp()
  const [product, setProduct] = useState(false);
  const [productloading, setLoading] = useState(true);
  const [sendgift, setSendgift] = useState(false);
  const [walletstatus, setWalletstatus] = useState(false);
  let navigate = useNavigate();
  
      const connectWallet = (state) => {
            
        setLoading(true);
        connectWalletPressed(state,function(){
          connect()
            setLoading(false);
            var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWalletstatus(true);
            }else{
                if(status==='METAMASK_NOT_INSTALLED' || status==='NOT_INSTALLED'){
                    alert('Install Metamask to continue');
                }else{
                    alert('Unable to connect. Please try again');
                }
            }
        })
       
    }
  
  const getProduct = (component) => {
   
  
    
      if(productloading==true){
    axiosClient()
    .get(`/wakenft/v1/verifyclaim?code=`+component)
    .then((res) => {
        
        if(res.data.requirements!=='fail'){
            setProduct(res.data);
        }
        setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
      }
  }
  useEffect(() => {
      var status = localStorage.getItem('walletstatus');
      if(status=='CONNECTED'){
          setWalletstatus(true);
      }else{
          setWalletstatus(false);
      }
  }, []);
  
  const claimit = () =>{
      axiosClient()
    .post(`/wakenft/v1/claimgift`,{
        code:component,
        walletaddress: localStorage.getItem('wallet')
    })
    .then((res) => {
         navigate(res.data.redirect); 
    })
    .catch(() => {
      alert('Something went wrong. Please try again');
    });
  }

  
  return (
    <div className="nft_market">
      <h3>Claims</h3>
      {getProduct(component)}
      {productloading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ):(<>
    <div className="colslidet">
        {product ? (<>
        <h3>{product.name}</h3>
     
        <div className="colslidew">
          <img src={product.image} alt="" />
        </div>
       
       {product.requirements=='walletaddress' ? (
                <>
                {walletstatus ?(
                                        <button className="btn btn-primary" onClick={claimit}>Claim It</button>
                ):(
                  <button className="btn btn-primary" onClick={connectWallet}>Connect Wallet and Claim</button>
                )}
                </>
                
                ):(
                  <button className="btn btn-primary" onClick={claimit}>Claim It</button>
                )}
             
                
                </>):(<> <h3>Invalid Gift Claim</h3></>)}
                </div> 
                </>    )}  
       
    </div>
  );
}
