import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import Redemption1 from "../../components/redemption1";
import Redemption2 from "../../components/redemption2";
import Redemption3 from "../../components/redemption3";
import { useParams } from "react-router-dom";
import axiosClient from "../../helper";
import Myaccount from "../../components/myaccount";
import Gift from "../../components/gift";

export default function Index() {
  const { component } = useParams();
  const [product, setProduct] = useState(true);
  const [productloading, setLoading] = useState(true);
  const [sendgift, setSendgift] = useState(false);
  const getProduct = (component) => {
   
  
    
      if(productloading==true){
    axiosClient()
    .get(`/wakenft/v1/usernft?slug=`+component+`&walletaddress=`+localStorage.getItem('wallet'))
    .then((res) => {
        
        console.log(res.data.data);
      setProduct(res.data.data);
        setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
      }
  }
  
  const sendAsGift = () =>{
      setSendgift(true);
  }
  const handleSendgift = () =>{
      console.log('called');
       setSendgift(false);
  }
  
  return (
    <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/dashboard">
    
          <ArrowBackIcon style={{ color: "gray", fontSize: "35px" }} />
 
        </Link>
        <Myaccount  />
       
      </div>
      {getProduct(component)}
      {productloading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ):(<>
    <div className="colslidet">
        <h3>{product.name} - {product.color}</h3>
        </div>
        <div className="colslidew">
          <img src={product.image} alt="" />
        </div>
        <button className="mt-3 mb-3 giftbtn" onClick={sendAsGift}>Send NFT as Gift</button>
     <h1
                    className="text-center d-block m-0"
                    style={{ fontWeight: "200", fontSize:'20px' }}
                  >
                    REDEMPTIONS
                  </h1>
                    <p className="m-0">Available Redemptions ({product.available})</p>
                  <p className="m-0">Redeemed Redemptions ({product.redeemed})</p>
                          <div className="redemptions">
                 {product.redemptions?.map((item, indexs) => (
                         <div className="redemption">
                    <div className="redemptionname"><Link to={`/radeem-grow/${item.slug}/${component}`} >{item.name}</Link></div>
                    <div className="redemptiondetail">
                      <div className="redemptionleft snft">
           
                  
                    
                      <p>available ({item.available})</p>
            <p>redeemed ({item.redeemed})</p>
                      </div>
                    </div>
                  </div>
                 ))}
                  
                </div>
            </>)}
            {sendgift && <Gift item={{
                name:product.name,
                slug:product.slug,
                type:'nft'
            }} onClose={handleSendgift} />}
    </div>
  );
}
