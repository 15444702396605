import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { GifBoxOutlined, Sms, Wallet, EmailOutlined, CloseOutlinedIcon } from '@mui/icons-material';

import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import axiosClient from "../lib/helper";



export default function Index({item, onClose}) {
  
  const [gift, setGift] = React.useState(true);
  const [giftsent, setGiftsent] = React.useState(false);
  const [email, setEmail] = React.useState(null);

  let navigate = useNavigate();
  
  
  const handleOpenGift = () => {
    setGift(true);
  };
  
  const handleOpenGiftsent = () => {
      axiosClient()
    .post(`/wakenft/v1/sendgift`,{
        to:email,
        subject:'Got a gift',
        content:'You got a gift of '+item.name,
        slug:item.slug,
        type:item.type,
        name:item.name?item.name:'',
        service:item.service?item.service:'',
        walletaddress: localStorage.getItem('wallet')
    })
    .then((res) => {
            console.log(res.status)
            if(res.status===200){
                navigate("/gift-thanks/"+item.name);    
                setGift(false);
                setGiftsent(true);
            }else{
                alert(res.data.message);
            }
    })
    .catch(() => {
      console.log('sendEmail, error');
    });
  

  };  
  const handleCloseGift = () => {
    setGift(false);
    onClose(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
    onClose(false);
     setGift(true);
  };  
  const handleNavigateAccount = (e) => {
    e.preventDefault();
    console.log(item)
   // navigate("/gift-thanks/"+item.title.rendered, { replace: true });
  };  

  return (
    <>
     <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="email" placeholder='Enter Email' onChange={(e)=>{ setEmail(e.target.value)}}/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="tel" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item.name} gift is sent.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
        </div>
                <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
    </>
  );
}
