import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Peni from "../../assets/peni.png";
import { useNavigate } from "react-router-dom";
import Vital1 from "../../assets/vital1.png";
import Vital2 from "../../assets/vital2.png";
import Vital3 from "../../assets/vital3.png";
import Vital4 from "../../assets/vital4.png";
import Vital5 from "../../assets/vital5.png";
export default function Index({ wallett, setWallett, walletAddress, loading }) {
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setBack(true);

    setOpen2(true);
    setOpen(false);
    setBack(true);
  };

  const handleClick3 = () => {
    setOpen3(true);
    setOpen2(false);
    setBack(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
    setBack(false);
  };

  const handleClose3 = () => {
    setOpen2(false);
    setOpen(false);
    setOpen3(false);
    setBack(false);
  };

  const getWalletMessage = () => {
    if (loading) {
      return "Loading Wallet...";
    }
    setWallett(walletAddress);

    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };
  return (
    <>
      <div className="nft_market">
        <div className="d-flex justify-content-between">
          <Link to="/retreat_01">
            <a>
              <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
            </a>
          </Link>
          <div className="dropdown">
            <Button
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                borderRadius: "40px",
                padding: "10px",
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: "13px",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
            >
              {wallett ? <>Welcome{getWalletMessage()}</> : null}
            </Button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="penisenvy-video.html">
                View Your Grow
              </a>
              <a className="dropdown-item" href="vitals.html">
                View Vitals
              </a>
              <a className="dropdown-item" href="index.html">
                Logout
              </a>
            </div>
          </div>
        </div>

        <main className="nft_market_">
          <div className="container pt-0">
            <div className="row">
              <div className="col-12 p-0">
                <h1 className="text-center d-block">View Vitals</h1>
              </div>
            </div>
            <div className="row align-items-center pt-3">
              <img src={Vital1} alt="" />
            </div>
            <div className="row align-items-center pt-3">
              <img src={Vital2} alt="" />
            </div>
            <div className="row align-items-center pt-3">
              <img src={Vital3} alt="" />
            </div>
            <div className="row align-items-center pt-3">
              <img src={Vital4} alt="" />
            </div>
            <div className="row align-items-center pt-3">
              <img src={Vital5} alt="" />
            </div>
          </div>
        </main>
        {/* <div className="confirmpopup">
          <button className="btn-close" />
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
              You will receive updates to your email when your Mushroom grow pod
              is ready to start.{" "}
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a href="redeem-nft.html" className="redeemBtn mt-3">
              Go to Redemption Store
            </a>
          </div>
        </div> */}
        <div className={back ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
