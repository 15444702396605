import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { ethers } from "ethers";
import erc20Abi from './abi/erc20.json'
import erc721Abi from './abi/erc721.json'
import axiosClient from "./helper";
export const CartContext = createContext();

export function CartProvider({ children }) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [store, setStore] = useState();

  const [provider, setProvider] = useState();
  const [account, setAccount] = useState();
  let [Contract, setContract] = useState();
  let [TokenContract, setTokenContract] = useState();
  const [tokenBalance, setTokenBalance] = useState();
  const [approvedToken, setAllowance] = useState(0);
  const TOKEN_ADDR = "0x42EBA1d39F133dcE76f073F954BF69E6BCB898D1";
  const CONTRACT_ADDR = "0x94Ae991864EC4B9aCc4800bA312647B0E0993cB7";

  const connect = async () => {
    if (!window.ethereum?.request) {

      alert("MetaMask is not installed!");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    setProvider(provider);
    setAccount(accounts[0]);
    
  };

  const approve = async () => {
    console.log(TokenContract)
    try {

      await TokenContract?.approve(CONTRACT_ADDR, "1000000000000000000000000000")
      setAllowance(100000000000000)
    }
    catch (error) {
      console.log(error)
    }
  }

  const allowance = async (account) => {
  
    setAllowance((await TokenContract.allowance(account, CONTRACT_ADDR)).toString())
  
  }

  const buyNFT = async () => {
    const txHash = await Contract.mint(account);
    alert("nft minting ...:" + txHash.hash);
    alert("100 Token sent to :" + CONTRACT_ADDR);
    console.log(txHash)
  }

  useEffect(() => {
    setLoader(true);
    if (provider && account) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner();
      const token = new ethers.Contract(TOKEN_ADDR, erc20Abi, signer);
      const nftContract = new ethers.Contract(CONTRACT_ADDR, erc721Abi, signer);
      setTokenContract(TokenContract = token)
      setContract(Contract = nftContract)
      console.log(Contract)
      console.log(TokenContract)
      allowance(account)
    }
    axiosClient()
      .get(`/wc/store/v1/products`)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });

    axiosClient()
      .get(`/wp/v2/redemption?order=asc`)
      .then((res) => {
        setStore(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [account]);

  return (
    <CartContext.Provider value={{ allowance,approvedToken, approve, connect, buyNFT, data, loader, store }}>
      {children}
    </CartContext.Provider>
  );
}

function useCartDapp() {

  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error(" useCartDapp must be used within a useCartDapp");
 
  }
  return context;
}


export { createContext, useCartDapp};
