import React, {useState, useEffect} from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Myaccount from "../../components/myaccount";
import axiosClient from "../../lib/helper";
export default function Index() {
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate();
useEffect(() => {
    setLoading(true)
     axiosClient()
      .get(`/wakenft/v1/userredepmtions?walletaddress=`+localStorage.getItem('wallet'))
      .then((res) => {
     
        setData(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [])  


  return (
    <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/">
       
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
  
        </Link>
        <Myaccount key={2}/>
      </div>
      <main className="nft_market_">
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
                REDEMPTION STORE
              </h1>
            </div>
          </div>

          <div className="row  mt-4">
            {isLoading ? (
              <div
                style={{
                  height: "100vh",
                  paddingTop: "270px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {data?.map((x) => (
                  <Grid container key={x?.redemption.post_name}>
                    <Grid
                      onClick={() => navigate(`/radeem-grow/${x?.redemption.post_name}`)}
                      item
                      xs={12}
                      className="redemption-item"
                      key={`x`+x?.redemption.post_name}
                    >
                  
                      <Grid style={{ width: "100%", height: "114px" }}  key={`y`+x?.redemption.post_name}>
                        <img
                          className="redemption-image"
                          src={`${x?.image}`}
                          alt=""
                        />
                      </Grid>
                      <Grid className="redemption-text"  key={`z`+x?.redemption.post_name}>
                      <span className="rnftavailable">{x?.available}</span>
                        <div className="redemption_store_text">
                         
                            {x?.heading.split('|').map(function(item,indx) {
    return (
       <span key={'xx'+`${indx}`}>{item}</span>
    )
 })}
                      
                          
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
