import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Landing from "./pages/landing/index";
import Dashboard from "./pages/dashboard/index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NftMarket from "./pages/nft_market/nft_market";
import NftMarketSingle from "./pages/nft_market/nft_market_single";
import RadeemNft from "./pages/redeem-nft";
import RadeemGrow from "./pages/radeem-grow";
import Penisenvy from "./pages/penisenvy";
import PenisenvyVideo from "./pages/penisenvy_video";
import MuiAlert from "@mui/material/Alert";
import { useCartDapp } from './Context'
import axios from "axios";
import { CartProvider } from "./Context";
import Retreat from "./pages/retreat_01";
import Vitals from "./pages/vitals";
import Mynft from "./pages/mynft";
import Giftthanks from "./pages/giftthanks";
import Claim from "./pages/claim";
import { connectWallet , connectWalletPressed} from "./lib/wallet";
import axiosClient from "./lib/helper";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function App() {
  const [walletAddress, setWallet] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState({ show: false, message: "" });
  const [status, setStatus] = useState("");
  const [open2, setOpen2] = useState(false);
  const [data2, setData2] = useState();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState();
  const [snackType, setSnackType] = useState();
  const [disconnect, setDisconnect] = useState(false);
  const [wallett, setWallett] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

    const connectWallet = (state) => {
            
        setLoading(true);
        connectWalletPressed(state,function(){
          
            setLoading(false);
            var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWallet(true);
            }else if(status === 'METAMASK_NOT_INSTALLED'){
                setShowError({ show: true, message:'METAMAST Not Installed' });
            }
        })
       
    }
  /*const connectWalletPressed = async (hello) => {
    const walletResponse = await connectWallet(window);
    if (walletResponse.error) {
      handleWalletError(walletResponse.error);
    }else if(walletResponse.status==='METAMASK_NOT_INSTALLED'){
      setShowError({ show: true, message:'METAMAST Not Installed' });
    }
    addWalletListener();
    
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

    localStorage.setItem("token", walletResponse.address);
    if (walletResponse.address) {
      setWallett(true);
      setDisconnect(true);
      setOpen2(true);
    }

    if (hello) {
      setOpen2(true);
    }
  }; */
  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
          setStatus("NOT_CONNECTED");
        }
      });
      window.ethereum.on("chainChanged", (chainId) => {
        handleChainChanged(chainId);
      });
    } else {
      setStatus("METAMASK_NOT_INSTALLED");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError({ show: false, message });
  };

  const handleChainChanged = (_chainId) => {
    // We recommend reloading the page, unless you must do otherwise
    window.location.reload(false);
  };
  const handleWalletError = (error) => {
    const code = error.code;
    const message = error.message;

    if (message === "Already processing eth_requestAccounts. Please wait.") {
      const content = "Please confirm on your metamask extension";
      setShowError({ show: true, message: content });
    } else {
      setShowError({ show: true, message });
    }
  };

  useEffect(() => {
    if (walletAddress) {
  
        
        axiosClient().post(`/wakenft/v1/user`, {
                walletaddress: walletAddress
              })
              .then((res) => {
                  
                    localStorage.setItem('email', res.data.email);
                    localStorage.setItem('name', res.data.name);
                    setOpen(true);
                    setSnackType(true);
              }); 
        
    }
  }, [walletAddress]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" style={{ cursor: "pointer" }} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <BrowserRouter  basename={'/'}>
          <Routes>
            <Route path="/" element={<Landing wallett={wallett} />} />
            <Route path="/dashboard" element={<Dashboard />} />
             <Route
              path="/nft-market/:component/"
              element={
                <CartProvider>
                  <NftMarketSingle/>
                </CartProvider>
              }
            />
            <Route
              path="/nft-market"
              element={
                <CartProvider>
                  <NftMarket />
                </CartProvider>
              }
            />
           
            <Route
              path="/redeem-nft"
              element={
                <CartProvider>
                  <RadeemNft 
                  />
                </CartProvider>
              }
            />
            <Route
              path="/radeem-grow/:component/:nftslug/:code"
              element={
                <RadeemGrow 
                />
              }
            />
            <Route
              path="/radeem-grow/:component/:nftslug"
              element={
                <RadeemGrow 
                />
              }
            />
            <Route
              path="/radeem-grow/:component"
              element={
                <RadeemGrow 
                />
              }
            />
            <Route
              path="/mynft/:component"
              element={
                <Mynft/>
              }
            />
            <Route
              path="/penisenvy"
              element={
                <Penisenvy  />
              }
            />
            <Route
              path="/penisenvy_Video"
              element={
                <PenisenvyVideo 
                />
              }
            />
            <Route
              path="/retreat/:component/:nftslug/:code"
              element={
                <Retreat 
                />
              }
            />
            <Route
              path="/retreat/:component/:nftslug"
              element={
                <Retreat 
                />
              }
            />
            <Route
              path="/retreat/:component"
              element={
                <Retreat 
                />
              }
            />
            <Route
              path="/claim/:component"
              element={
                <Claim />
              }
            />
            <Route
              path="/vitals"
              element={
                <Vitals 
                />
              }
            />
            <Route
              path="/gift-thanks/:component"
              element={
                <Giftthanks />
              }
            />
          </Routes>
        </BrowserRouter>

        <div>
          <Snackbar
            open={showError.show}
            autoHideDuration={6000}
            onClose={handleErrorClose}
            action={action}
          >
            <Alert
              onClose={handleErrorClose}
              severity={snackType ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {showError.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
}

export default App;
