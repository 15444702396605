import React, { useState, useEffect } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Metamask from "../../assets/metamask.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Slider from "react-slick";
import Myaccount from "../../components/myaccount";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosClient from "../../helper";
import CircularProgress from "@mui/material/CircularProgress";

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);
  const [nfts, setNfts] = useState(false);
  const [redeems, setRedeems] = useState(false);
  const [loadingredeem, setLoadingredeem] = useState(true);
  const [wallet, setWallet] = useState(false);
  const [loadingnft, setLoadingnft] = useState(true);
  const [yourname, setYourname] = useState('');
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode:true,
    centerPadding:"0px",
    arrows:false,
    focusOnSelect:true
  }  
  let navigate = useNavigate();
  
  useEffect(() => {
      setYourname(localStorage.getItem('yourname'));
      setLoadingnft(true);
     axiosClient()
    .get(`/wakenft/v1/nfts?walletaddress=`+localStorage.getItem('wallet'))
    .then((res) => {
        
     console.log(res.data.data);
      setNfts(res.data.data);
         setLoadingnft(false);
    })
    .catch(() => {
           setLoadingnft(false);
    });
    
    setLoadingredeem(true);
     axiosClient()
    .get(`/wakenft/v1/userredeems?walletaddress=`+localStorage.getItem('wallet'))
    .then((res) => {
        
     console.log(res.data.data);
      setRedeems(res.data.data);
         setLoadingredeem(false);
    })
    .catch(() => {
           setLoadingredeem(false);
    });
    
  }, [])  
  
  const handleClick = () => {
  
    if (wallet) {
      setOpen(false);
      navigate("/dashboard", { replace: true });
    } else {
      setOpen(true);
      setBack(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/nft-market", { replace: true });
  };

  const handleNavigate2 = (e) => {
    e.preventDefault();
    if (wallet) {
      navigate("/dashboard", { replace: true });
    } else {
      navigate("/nft-market");
    }
  };
  return (
    <>
      <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/">
     
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
       
        </Link>
        <Myaccount  />
      </div>
        <main className="nft_market_">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1
                  className="text-center d-block m-0"
                  style={{ fontWeight: "lighter" }}
                >
                  WELCOME {yourname}!
                </h1>
                <p
                  className="text-center"
                  style={{ fontWeight: "lighter", fontSize: "16px" , marginBottom:"30px"}}
                >
                  You can view your NFTs collection<br />
and keep track of your redemptions on this screen
                </p>
                {loadingnft ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <>
               
               {Object.keys(nfts).map((x, index) => (
                       <>
                  {nfts[x].length > 3 ? (
                      <div className="collection"  key={`col-`+x}>
                
                <h1
                    className="text-center d-block"
                    style={{ fontWeight: "400", fontSize:'20px' }}
                  >
                    {x} COLLECTION ({nfts[x].length})
                  </h1>
                          <Slider {...settings} key={`slider-`+x}>
                  
                         {nfts[x]?.map((xs, indexs) => (
                           <div className="colslide"  key={`colslider-`+x+`-`+xs}>
                   <Link to={`/mynft/${xs.slug}`}>
                        <img src={xs.image} />
                    </Link>
          </div>
                ))}
                </Slider>
                 </div>
                  ) : (
                  <div className="collection nonslidercontrainer"  key={`col-`+x}>
                
                <h1
                    className="text-center d-block"
                    style={{ fontWeight: "400", fontSize:'20px' }}
                  >
                    {x} COLLECTION ({nfts[x].length})
                  </h1>
                    <div class="nonslider">
                        {nfts[x]?.map((xs, indexs) => (
                           <div className="colslide"  key={`colslider-`+x+`-`+xs}>
            <Link to={`/mynft/${xs.slug}`}>
                        <img src={xs.image} />
                    </Link>
          </div>
                ))}
                    </div>
                     </div>
                  )} 
                  </>
               
                       ))}
                       
               
        </>)}
            
             
              
              <h1
                    className="text-center d-block m-0"
                    style={{ fontWeight: "200", fontSize:'20px' }}
                  >
                    REDEMPTIONS
                  </h1>
                   {loadingredeem ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <>
        <p className="m-0">Available Redemptions ({redeems.totals.available})</p>
                  <p className="m-0">Redeemed Redemptions ({redeems.totals.redeemed})</p>
        </>
      )}
                
                

                <div className="redemptions">
                 {redeems.items?.map((item, indexs) => (
                         <div className="redemption">
                    <div className="redemptionname">{item.title}</div>
                    <div className="redemptiondetail">
                      <div className="redemptionleft">
                      {item.desc}
                      <span>{item.detail}</span>
                      </div>
                      <div className="redemptionright">
                      <p>available ({item.available})</p>
            <p>redeemed ({item.redeemed})</p>
                      </div>
                    </div>
                  </div>
                 ))}
                  
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className={open ? "confirmpopupconnect" : "confirmpopupconnect2"}>
          <button className="btn-close" onClick={handleClose}></button>
          <div className="confirmpopup-content2">
            <img
              width="50px"
              height="40px"
              style={{ marginTop: "10px" }}
              src={Metamask}
              alt=""
            />
            <p className="metamask2">Connect Metamask Wallet</p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={(e) => handleNavigate2(e)} className="redeemBtn mt-3">
              Connect Wallet
            </a>
          </div>
        </div>
        <div className={back ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
