import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Peni from "../../assets/peni.png";
import { useNavigate } from "react-router-dom";
import Feild2 from "../../assets/feild2.png";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import dayjs, { Dayjs } from 'dayjs';
import { GifBoxOutlined, Sms, Wallet, EmailOutlined } from '@mui/icons-material';
import axiosClient from "../../helper";
import { useParams } from "react-router-dom";
import Myaccount from "../../components/myaccount";
import { useCookies } from 'react-cookie';
import CircularProgress from "@mui/material/CircularProgress";

function disableWeekends(datet) {
 // var date = new Date(datet);
  
  return (datet.getDay()==1 || datet.getDay()==2 || datet.getDay()==3)?true:false;
}

export default function Index() {
  let navigate = useNavigate();
    const { component, code } = useParams();
  const [date, setDate] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [gift, setGift] = React.useState(false);
  const [giftsent, setGiftsent] = React.useState(false);  
  const [email, setEmail] = React.useState(null);
  const [product, setProduct] = useState(true);
  const [slotid, setSlotid] = React.useState(1);
  const [slotlabel, setSlotlabel] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [loadingoptions, setLoadingOptions] = React.useState(false);

  const [time, setTime] = React.useState(null);
  
  const loadSlots = () => {
     
      var soptions = [];
      setOptions([]);
      setTime([]);
      setSlotlabel('');
      var from = dayjs();
      var to = dayjs().add(150, 'day');
       console.log('product',product);
      axiosClient()
      .get('https://wake.advancedcare.com/api/public/v1/booking?action=time_slot&service_id='+product.service_id+'&from='+Number(from.valueOf())+'&to='+Number(to.valueOf()))
      .then((res) => {
          console.log(res.data);
          console.log(res.data.result);
          if(res.data.result){
              var fstart = Number(from.valueOf());
              var fend = Number(to.valueOf());
              for(var ii=0; ii<res.data.result.length; ii++){
                  
                  console.log(new Date(res.data.result[ii].start));
                  if(res.data.result[ii].start >= fstart && res.data.result[ii].end <= fend ){
              soptions.push({
                  label:dayjs(res.data.result[ii].start).format('ll')+' - '+(dayjs(res.data.result[ii].end).add(product.duration, 'day').format('ll')),
                  value:res.data.result[ii].id,
                  date:dayjs(res.data.result[ii].start).format('DD-MM-YYYY'),
                  time:dayjs(res.data.result[ii].start).format('hh:mm:ss'),
                  start:res.data.result[ii].start
              });
                }
            }
            var byDate = soptions.slice(0);
                byDate.sort(function(a,b) {
                    return a.start - b.start;
                });
            console.log(soptions,byDate)
            setOptions(byDate);
            setLoadingOptions(false);
          }
        
      })
      .catch(() => {
          setLoadingOptions(false);
      });
  }
  
  
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
useEffect(() => {
    setLoadingOptions(true);
     axiosClient()
    .get(`/wakenft/v1/userredepmtions?slug=`+component+`&walletaddress=`+localStorage.getItem('wallet'))
    .then((res) => {
      setProduct(res.data.data[0]);
     
          loadSlots();
   
        
    })
    .catch(() => {
        setLoadingOptions(false);
    });
  }, [])  
const handleOpenGift = () => {
    setOpen3(false);
    setGift(true);
  };
  const handleOpenGiftsent = () => {
      
   axiosClient()
    .post(`/wakenft/v1/sendgift`,{
        to:email,
        subject:'Got a gift',
        content:'You got a gift of Wake Field'
    })
    .then((res) => {
            navigate("/gift-thanks/Wake Field", { replace: true });    
            setOpen(false);
            setGift(false);
            setGiftsent(true);
    })
    .catch(() => {
      console.log('sendEmail, error');
    });

  };  
  const handleCloseGift = () => {
    setGift(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
  };  
  const handleNavigateAccount = (e) => {
    e.preventDefault();

    navigate("/dashboard", { replace: true });
  };

  const handleClick = () => {
     if(slotlabel!==''){
         setOpen(true);
     
    setBack(true);

    setOpen2(true);
    setOpen(false);
    setBack(true);
     }else{
         alert('Please select a date');
     }
  };

  const handleClick3 = () => {
 
    
   axiosClient()
    .post(`/wakenft/v1/redeem`,{
        email:localStorage.getItem('email'),
        walletaddress:localStorage.getItem('wallet'),
        slug:product.redemption.post_name,
        date:date,
        time:time.value,
        slotid:slotid,
        serviceid:product.service_id,
        clinicid:product.clinicid,
        timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
        code:code
    })
    .then((res) => {
            setOpen3(true);
            setOpen2(false);
            setBack(true);
    }).catch(() => {
      console.log('sendEmail, error');
    });
     
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
    setBack(false);
  };

  const handleClose3 = () => {
    setOpen2(false);
    setOpen(false);
    setOpen3(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/dashboard");
  };

 
  const handlerange = (ranges) =>{
    console.log(ranges.selection);

    setRange([ranges.selection])
  }

  return (
    <>
      <div className="nft_market">
        <div className="d-flex justify-content-between">
          <Link to="/dashboard">
         
              <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
         
          </Link>
          <Myaccount />
        </div>

        <main className="nft_market_ p-0">
          <div className="container pt-5">
            <div className="row">
              <div className="col-12 p-0">
                <h1
                  className="text-center d-block"
                  style={{ fontWeight: "lighter" }}
                >
                  REDEEM RETREAT
                </h1>
                </div>
                {loadingoptions?(
               <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
                ):(
               <> 
       <div className="col-12 p-0">
                <h2 className="text-center d-block">
                  Select Date
                </h2>
              </div>
              <div className="col-12 main-info p-0">
                <img src={Feild2} alt="" className="img-fluid" />
              </div>
              <div className="col-12 main-info p-0 pt-3">
                {options.map((option, i) => { 
               
                return (<div key={`d`+i} class="slotbox" >
                <input type="radio" name="slotid" value={option.value} data-label={option.label} onClick={(e) => {
                              setSlotlabel(e.target.dataset.label);
                              setSlotid(e.target.value);
                            }} 
                            /> <label class="slotpickerday">{option.label}</label></div>)
              
                })}
              </div>
              <div className="col-12 main-info p-30">
           
              </div>
              <div className="col-12 main-info p-30">
                <button
                  onClick={handleClick}
                  className="redeemBtn mt-4 showselectorder"
                >
                  Redeem Retreat
                </button>
              </div>
              </>)}
            </div>
          </div>
        </main>
        <div className={open2 ? "orderbox" : "orderbox2"}>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "right",
            }}
          >
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose2} />
          </div>

          <div>
            <button className="btn-close" />
            <div className="confirmpopup-content">
              <div className="orderbox-content">
                <p>Retreat Selected</p>
                <h1 className="d-block">WAKE FIELD</h1>
                <p>Date(s) Selected</p>
                <h1 className="d-block dselected">{slotlabel}</h1>
              </div>
            </div>
            <div className="confirmpopup-bottom">
              <a onClick={handleClick3} className="redeemBtn mt-3 showconfirm">
                Confirm Retreat
              </a>
            </div>
          </div>
        </div>

        <div className={open3 ? "confirmpopup" : "confirmpopup2"}>
          <div
            style={{
              paddingTop: "5px",

              textAlign: "right",
            }}
          >
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose3} />
          </div>
          <div>
            <div className="confirmpopup-content">
              <h1 className="text-center d-block">THANK YOU</h1>
              <p>
                Your retreat is booked. You will recieve an email with further
                details.
              </p>
            </div>
            <div className="confirmpopup-bottom">
              <a onClick={(e) => handleNavigate(e)} className="redeemBtn mt-3">
                go to Dashboard
              </a>
             
            </div>
          </div>
        </div>

        <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="text" placeholder='Enter Email'/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="text" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your retreat is sent as a gift.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
        </div>
        <div className={open ? "overloay1" : "overloay2"}></div>
        <div className={open2 ? "overloay1" : "overloay2"}></div>
        <div className={open3 ? "overloay1" : "overloay2"}></div>
        <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
