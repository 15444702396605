import React, { useState, useEffect } from "react";
import Cardimg from "../../assets/cardimg.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideoLabelOutlinedIcon from "@mui/icons-material/VideoLabelOutlined";
import { Link } from "react-router-dom";
import contractAbi from '../../abi/erc721.json'
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Eth from "../../assets/ETH.png";
import Metamask from "../../assets/metamask.png";
import { useContext } from "react";
import Myaccount from "../../components/myaccount";
import { useCookies } from 'react-cookie';
import axiosClient from "../../lib/helper";
import {connectWalletPressed} from "../../lib/wallet";
import Web3 from 'web3'


export default function Nft_market() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [back, setBack] = useState(false);
  const [token, setTokken] = useState("");
  const [buying, setBuying] = useState(false);
  const [hidehome, setHidehome] = useState(false);
  const [productloading, setProductLoading] = useState(false);
  const [products, setProduct] = useState(false);
  const [email, setEmail] = React.useState('');
  const [yourname, setYourName] = React.useState('');
  
//  const [cookies, setCookie] = useCookies(['email']);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [wallet, setWallet] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [refresh, doRefresh] = useState(0);
   
  let navigate = useNavigate();

useEffect(() => {
    console.log('setWallet',wallet)
    setEmail(localStorage.getItem('email'));
    setYourName(localStorage.getItem('yourname'));
    setLoading(true)
     axiosClient()
      .get(`/wc/store/v1/products`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
      var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWallet(true);
            }
  }, []);  

  const handleYourname = (e) =>{
      
      setYourName(e.target.value);
      e.preventDefault();
  }
  const handleEmail = (e) =>{
      localStorage.setItem('email',e.target.value);
      setEmail(e.target.value);
      e.preventDefault();
  }
  const showProduct = (x) => {
  
    setProductLoading(true);
    setHidehome(true);
    //console.log(hidehome)
    var vis = [];
    for(var i=0;i<x.variations.length;i++){
      vis.push(x.variations[i].id);
    };
    axiosClient()
    .get(`/wc/store/v1/products/?order=asc&type=variation&include=`+vis.join(','))
    .then((res) => {
      setProduct(res.data);
      setProductLoading(false);
    })
    .catch(() => {
      setProductLoading(false);
    });
  };

  const handleClick = (x) => {
    var status = localStorage.getItem('walletstatus');
    if(status==='CONNECTED'){
        setWallet(true);
    }
    setBuying(x);
    console.log('walletstatus',status)
    console.log('walletaddress',localStorage.getItem('wallet'));
    console.log('wallet',wallet);
    
    if (status==='CONNECTED') {
      setOpen(false);
      setBack(true);
      setOpen2(true);
      setOpen(false);
      setBack(true);
    } else {
      setOpen(true);
      setBack(true);
    }
  };



  const handleClick3 = () => {
      var is_valid = true;
   console.log(email);
  if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)===false){
      is_valid = false;
      alert('Please enter a valid email');
  }

  if(is_valid){
      console.log(buying.prices.price/100)
      setConfirming(true);
    payMeta(localStorage.getItem('wallet'),buying.prices.price/100,function(r){
        console.log(r);
        setConfirming(false);
    }) 
 
   
 /*
    localStorage.setItem('email', email);
  localStorage.setItem('yourname', yourname);
   axiosClient()
    .post(`/wakenft/v1/purchase`,{
        email:email,
        yourname:yourname,
        buying:buying.id,
        walletaddress:localStorage.getItem('wallet')
    })
    .then((res) => {
        
        if(res.status===200){
            setOpen3(true);
            setOpen2(false);
            setBack(true);
        }
        
    }).catch(() => {
      console.log('sendEmail, error');
    });
    */
  }
    
  };
  async function transfer(receiver, tokenId){

    const contract= new window.web3.eth.Contract(
      contractAbi,
      "0xF6A8248fEdb79fb5D0810104648022BA9782310F"
      )
    await contract.transfer(receiver,tokenId)
  }
    async function payMeta(sender, strEther, cb) {
        localStorage.setItem('sending',0);
     //   try {
            await window.ethereum.enable();
            window.web3 = new Web3(window.ethereum);   
            console.log(window.web3.eth);
           // console.log(window.web3.eth.getBalance());
            // console.log(await window.web3.eth.getCode('0x1E499fB66273062a9EF06F4cD31c9660E65a5c58'));

            const contract= new window.web3.eth.Contract(
              contractAbi,
              "0x074E3598d0d9Bd462bE4A6cfbc1490c806D33236"
              )
              //0x1e499fb66273062a9ef06f4cd31c9660e65a5c58
              // 0xF6A8248fEdb79fb5D0810104648022BA9782310F
              console.log(contract)
              console.log(sender,strEther)
              await contract.methods.mint(sender,cb.toString(),window.web3.utils.toWei(strEther.toString())).send({from:sender, value:window.web3.utils.toWei(strEther.toString(),"ether")})
              .on("confirmation",async function(confirmationNumber, receipt){ 
                const tokenId= await contract.tokenIdCounter();
                 // cb(sendHash);
                 console.log('confirmation')
                 console.log(localStorage.getItem('sending'));
                 console.log(confirmationNumber)
                 console.log(receipt)
                 console.log(tokenId)
                 //setConfirming(false);
                 if(confirmationNumber===0){
                     
                 localStorage.setItem('sending',1);
                 localStorage.setItem('email', email);
                 localStorage.setItem('yourname', yourname);
                  axiosClient()
                   .post(`/wakenft/v1/purchase`,{
                       email:email,
                       yourname:yourname,
                       buying:buying.id,
                       walletaddress:localStorage.getItem('wallet'),
                       tx:receipt.events.Transfer.returnValues.tokenId
                   })
                   .then((res) => {

                       if(res.status===200){
                           setOpen3(true);
                           setOpen2(false);
                           setBack(true);
                            setConfirming(false);
                       }

                   }).catch(() => {
                      alert('Server Down. Please contact support with token ',receipt);
                   });
                }
             }).on("receipt",function(receipt){ 
                 console.log('receipt')
                 console.log(receipt)
             })
 
    }
  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  const handleClosee = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
    setBack(false);
  };
  const handleClose3 = () => {
    setOpen2(false);
    setOpen(false);
    setOpen3(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/dashboard", { replace: true });
  };



  const getWalletMessage = () => {

    var walletAddress = localStorage.getItem('wallet');
    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };
  
  const buyAfterConnect = () =>{
      connectWalletPressed(true, function(){
          
          
            var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWallet(true);
                doRefresh(1);
            }
            setTimeout(function(){
            console.log('walletstatus',status)
            console.log('walletaddress',localStorage.getItem('wallet'));
            console.log('wallet',wallet);
            console.log('refresh',refresh);

            if (status==='CONNECTED') {
              setOpen(false);
              setBack(true);
              setOpen2(true);
              setOpen(false);
              setBack(true);
          }
          },1000);
      })
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClosee}
      >
        <CloseIcon fontSize="small" style={{ cursor: "pointer" }} />
      </IconButton>
    </React.Fragment>
  );


  return (
    <div className="nft_market">
      <div
        style={{ justifyContent: "space-between", display: "flex" }} className="d-flex justify-content-between"
      >
        {hidehome && (
          
          <a onClick={() =>setHidehome(false)} >
            <ArrowBackIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        )}
        {!hidehome && (
        <Link to="/">
      
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
         
        </Link>
      )}
      <Myaccount refresh={refresh} />
       
      </div>
      <main className="nft_market_">
      {hidehome && (
        <div className="container">
          <div className="col-12 p-0">
            <h1 className="text-center d-block" style={{ fontWeight: "lighter" }}>
              WAKE NFT MARKET
            </h1>
            <h2 className="text-center d-block">{buying.name}</h2>
          </div>
          {productloading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <>
                {products?.map((x, index) => (
                  <div
                    className="col-12 p-0 hi"
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() =>handleClick(x)}
                    key={`${x.id}+${index}`}
                  >
                    <div
                      className="market-image2"
                    >
                      <img
                        src={`${x?.images[0].src}`}
                        alt=""
                        className={index === 0 ? "img-fluidss2" : "img-fluidss"}
                      />
                    </div>
                    <div className="inner-info">
                      <h3><span className="itemname">{x?.name} </span><span className="itemcolor">{x.variation?.replace('Color:','')}</span></h3>
                      <div className="d-flex align-items-center justify-content-between">
                        <a onClick={() =>handleClick(x)}  className="showselectorder">
                          BUY NOW
                        </a>
                        <span>
                          <img src={Eth} alt="" className="img-fluid" />{" "}
                          {x?.prices.price/100} MATIC
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
         
      )}
      {!hidehome && (
        <div className="container">
          <div className="col-12 p-0">
            <h1 className="text-center d-block" style={{ fontWeight: "lighter" }}>
              WAKE NFT MARKET
            </h1>
            <h2 className="text-center d-block">Buy Artwork NFTs</h2>
          </div>
          <div
            className="row main-info"
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            {isLoading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <>
                {data?.map((x, index) => (
                  <div
                    className={index === 0 ? "col-12 p-0 hi" : "col-6 p-0"}
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() =>showProduct(x)}
                    key={`${x.id}+${index}`}
                  >
                    <div
                      className={index === 0 ? "market-image2" : "market-image"}
                    >
                      <img
                        src={`${x?.images[0].src}`}
                        alt=""
                        className={index === 0 ? "img-fluidss2" : "img-fluidss"}
                      />
                    </div>
                    <div className="inner-info">
                      <h3><span className="itemname">{x?.name} </span><span className="itemcolors">{x.attributes?.map((xx,xindex)=>(<>{xx.terms.map((term,iterms)=>(<>{term.name} </>))}</>))}</span></h3>
                      <div className="d-flex align-items-center justify-content-between">
                        <a onClick={() =>showProduct(x)}  className="showselectorder">
                          VIEW & BUY
                        </a>
                        <span>
                          <img src={Eth} alt="" className="img-fluid" />{" "}
                          {x?.prices.price_range.min_amount/100} - {x?.prices.price_range.max_amount/100} MATIC
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        )}
      </main>
      <div className={open ? "confirmpopupconnect" : "confirmpopupconnect2"}>
        <button onClick={handleClose} className="btn-close" />
        <div className="confirmpopup-content2">
          <img
            width="50px"
            height="40px"
            style={{ marginTop: "10px" }}
            src={Metamask}
            alt=""
          />
          <p className="metamask2">Connect Metamask Wallet</p>
        </div>
        <div className="confirmpopup-bottom">
          <Button
            className="redeemBtn mt-3 connectit"
            onClick={() => buyAfterConnect() }
            sx={{ p: 0 }}
            // className="wallet_btn"
          >
            {wallet ? (
              <>{getWalletMessage()}</>
            ) : (
              <p style={{ height: "7px" }}>Connect Wallet</p>
            )}
          </Button>
        </div>
      </div>

      <div className={open2 ? "orderbox" : "orderbox2"}>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "right",
          }}
        >
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose2} />
        </div>
        <div className="confirmpopup-content w-100">
          <div className="orderbox-content ob-bottom w-100">
            <p style={{ fontSize: "20px", fontWeight: "lighter" }}>
              Please enter your name and email to confirm the purchase.
            </p>
            <div className="row mt-3">
              <div
                className="col-12"
                style={{ justifyContent: "center", display: "grid" }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{ marginBottom: "20px ", borderRadius: "15px" }}
                  placeholder="Your Name"
                   onChange={handleYourname}
                   value={yourname}
                />
              </div>
            </div>
            <div className="row mt-0">
              <div
                className="col-12"
                style={{ justifyContent: "center", display: "grid" }}
              >
                <input
                  type="email"
                  className="form-control"
                  style={{ marginBottom: "20px ", borderRadius: "15px" }}
                  placeholder="Email Address"
                   onChange={handleEmail}
                   value={email}
                />
              </div>
            </div>
          </div>
        </div>
        {buying && (<>
        <div className="confirmpopup-content ob-bottom w-100">
          <div className="orderbox-content w-100">
            <p>NFT Selected</p>
            <h1 className="d-block" style={{ fontWeight: "lighter" }}>
            {buying.name} {buying.variation?.replace('Color:','')}
            </h1>
          </div>
        </div>
        <div className="confirmpopup-content ob-bottom w-100">
          <div className="orderbox-content w-100">
            <p>Payment Method</p>
            <h1 className="d-block eth" style={{ fontWeight: "lighter" }}>
              {buying.prices.price/100} MATIC
            </h1>
          </div>
        </div>
        </>)}
        <div className="confirmpopup-bottom">
          <a
            style={{ cursor: "pointer" }}
            onClick={handleClick3}
            className="redeemBtn mt-3 showconfirm"
          >
          {confirming && ('Please wait')}
          {!confirming && ('Confirm')}
            
          </a>
        </div>
      </div>
      <div className={open3 ? "confirmpopup" : "confirmpopup2"}>
        <div
          style={{
            paddingTop: "5px",

            textAlign: "right",
          }}
        >
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose3} />
        </div>
        <div className="confirmpopup-content pt-5">
          <h1 className="text-center d-block" style={{ fontWeight: "lighter" }}>
            CONGRATULATIONS
          </h1>
          <p>
            You now own the
            <br />
            {buying && (<>{buying.name} - {buying.variation?.replace('Color:','')}</>)} NFT
            <br />
            Thank you for contributing to Wake Network community. You can access
            your exclusive rewards from the redemption store.{" "}
          </p>
        </div>
        <div className="confirmpopup-bottom">
          <a
            onClick={(e) => handleNavigate(e)}
            href="#"
            className="redeemBtn mt-3"
          >
            Go to Redemption Store
          </a>
        </div>
      </div>

      <div className={back ? "overloay1" : "overloay2"}></div>
    </div>
  );
}
