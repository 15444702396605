import React, { useState, useEffect } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {connectWalletPressed} from "../lib/wallet";
import axiosClient from "../lib/helper";


export default function Myaccount({refresh}) {
    const [open, setOpen] = useState(false);  
    const [wallet, setWallet] = useState(false); 
    const [loading, setLoading] = useState(false);  
    let navigate = useNavigate();
    
    useEffect(() => {
        
        console.log('refresh',refresh);
        setLoading(true)
        var status = localStorage.getItem('walletstatus');
        console.log('walletstatus', status);
        if(status==='CONNECTED'){            
            setLoading(false);
            setWallet(true);
          
            axiosClient().post(`/wakenft/v1/user`, {
                walletaddress: localStorage.getItem('wallet')
              })
              .then((res) => {
               
              }); 
          
        }else{
            setLoading(false);
        }
       
    }, [refresh]);  
    
    
    const connectWallet = (state) => {
        console.log('connectWallet',state);    
        setLoading(true);
        connectWalletPressed(state,function(){
            
            setLoading(false);
            var status = localStorage.getItem('walletstatus');
            if(status==='CONNECTED'){
                setWallet(true);
            }
        })
       
    }
    const getWalletMessage = () => {
        if (loading) {
          return "Loading Wallet...";
        }
  
        var walletAddress = localStorage.getItem('wallet');
    
        return walletAddress.length > 0 ? (
          String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        );
      }; 
      const showDropdown = () =>{
      
        setOpen(!open);
      }
      const disconnectWallet = () =>{
          
          localStorage.setItem('token',"");
          localStorage.setItem('wallet',false);
          localStorage.setItem('walletstatus','');
          navigate("/");
      }
      const dahsboardWallet = () =>{
          navigate("/dashboard");
      }
      const MarketWallet = () =>{
          navigate("/nft-market");
      }
      return (
        <><div className="dropdown">
          {!wallet && (
        <Button
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            borderRadius: "40px",
            padding: "10px",
            backgroundColor: "#404040",
            color: "#fff",
            fontSize: "13px",
            textTransform: "capitalize",
            cursor: "pointer",
           
          }}
          onClick={() => connectWallet(true)}
        >
          {wallet ? <>Welcome {getWalletMessage()}</> : 'Connect Wallet'}
        </Button>
        )}
         {wallet && (
          <Button
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            borderRadius: "40px",
            padding: "10px",
            backgroundColor: "#404040",
            color: "#fff",
            fontSize: "13px",
            textTransform: "capitalize",
            cursor: "pointer",
           
          }}
          onClick={() => showDropdown(true)}
        >
          {wallet ? <>Welcome {getWalletMessage()}</> : 'Connect Wallet'}
        </Button>

         )}
         {open && (
        <div className="dropdown-menu open" aria-labelledby="dropdownMenuButton">
           <Button className="dropdown-item" onClick={() => dahsboardWallet(true)}>
            My NFTs
          </Button>
           <Button className="dropdown-item" onClick={() => MarketWallet(true)}>
            Marketplace
          </Button>          
          <Button className="dropdown-item" onClick={() => disconnectWallet(true)}>
            Logout
          </Button>
        </div>
        )}
      </div></>
      )
}