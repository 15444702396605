import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Peni from "../../assets/peni.png";
import { useNavigate } from "react-router-dom";

export default function Index({ wallett, setWallett, walletAddress, loading }) {
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setBack(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/penisenvy_Video");
  };

  const getWalletMessage = () => {
    if (loading) {
      return "Loading Wallet...";
    }
    setWallett(walletAddress);

    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };
  return (
    <>
      <div className="nft_market">
        <div className="d-flex justify-content-between">
          <Link to="/dashboard">
            <a>
              <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
            </a>
          </Link>
          <div className="dropdown">
            <Button
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                borderRadius: "40px",
                padding: "10px",
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: "13px",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
            >
              {wallett ? <>Welcome{getWalletMessage()}</> : null}
            </Button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="penisenvy-video.html">
                View Your Grow
              </a>
              <a className="dropdown-item" href="vitals.html">
                View Vitals
              </a>
              <a className="dropdown-item" href="index.html">
                Logout
              </a>
            </div>
          </div>
        </div>
        <main className="nft_market_">
          <div className="container pt-0">
            <div className="row">
              <div className="col-12 p-0">
                <h1
                  className="text-center d-block"
                  style={{ fontWeight: "lighter" }}
                >
                  REDEEM GROW
                </h1>
                <h2 className="text-center d-block">Schedule Grow</h2>
              </div>
            </div>
            <div className="row align-items-center pt-3">
              <div
                className="col-8 p-0"
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                <p>
                  Strain selected to grow
                  <p className="p-span font-bold">Penis Envy</p>{" "}
                </p>
              </div>
              <div className="col-4 p-0">
                <img src={Peni} className="img-fluid" alt="" />
              </div>
            </div>
            <div
              className="col-12 p-0 text-center pt-5"
              style={{ fontSize: "20px", fontWeight: "lighter" }}
            >
              <p>Please select a date to schedule grow. </p>
            </div>

            <div className="col-12 main-info p-0 ">
              <div className="calendar">
                <div className="month">
                  <a href="#" className="nav">
                    <i className="fas fa-angle-left" />
                  </a>
                  <div>
                    October <span className="year">2022</span>
                  </div>
                  <a href="#" className="nav">
                    <i className="fas fa-angle-right" />
                  </a>
                </div>
                <div className="days">
                  <span>Mon</span>
                  <span>Tue</span>
                  <span>Wed</span>
                  <span>Thu</span>
                  <span>Fri</span>
                  <span>Sat</span>
                  <span>Sun</span>
                </div>
                <div className="dates">
                  <button>
                    <time>1</time>
                  </button>
                  <button>
                    <time>2</time>
                  </button>
                  <button>
                    <time>3</time>
                  </button>
                  <button className="today">
                    <time>4</time>
                  </button>
                  <button>
                    <time>5</time>
                  </button>
                  <button>
                    <time>6</time>
                  </button>
                  <button>
                    <time>7</time>
                  </button>
                  <button>
                    <time>8</time>
                  </button>
                  <button>
                    <time>9</time>
                  </button>
                  <button>
                    <time>10</time>
                  </button>
                  <button>
                    <time>11</time>
                  </button>
                  <button>
                    <time>12</time>
                  </button>
                  <button>
                    <time>13</time>
                  </button>
                  <button>
                    <time>14</time>
                  </button>
                  <button>
                    <time>15</time>
                  </button>
                  <button>
                    <time>16</time>
                  </button>
                  <button>
                    <time>17</time>
                  </button>
                  <button>
                    <time>18</time>
                  </button>
                  <button>
                    <time>19</time>
                  </button>
                  <button>
                    <time>20</time>
                  </button>
                  <button>
                    <time>21</time>
                  </button>
                  <button>
                    <time>22</time>
                  </button>
                  <button>
                    <time>23</time>
                  </button>
                  <button>
                    <time>24</time>
                  </button>
                  <button>
                    <time>25</time>
                  </button>
                  <button>
                    <time>26</time>
                  </button>
                  <button>
                    <time>27</time>
                  </button>
                  <button>
                    <time>28</time>
                  </button>
                  <button>
                    <time>29</time>
                  </button>
                  <button>
                    <time>30</time>
                  </button>
                  <button>
                    <time>31</time>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 main-info p-0">
              <a
                style={{ cursor: "pointer" }}
                onClick={handleClick}
                className="redeemBtn mt-3 showconfirm"
              >
                Confirm
              </a>
            </div>
          </div>
        </main>
        <div className={open ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            {/* <button onClick={handleClose} className="btn-close" /> */}
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
              You will receive updates to your email when your Mushroom grow pod
              is ready to start.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigate} className="redeemBtn mt-3">
              View Your Grow
            </a>
          </div>
        </div>
        <div className={back ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
